<template>
  <div class="order_container"  v-loading="loading">
    <!-- 面包屑导航区域 -->
    <div style="margin-bottom: 20px">
    <el-row>
      <el-col :span="24" align="left">
            <el-button class="el-icon-edit" size="small" type="primary" @click="goback"
              >返回</el-button
            >
          </el-col>
       
     
     
    </el-row>
  </div>
   
    <!-- 卡片试图区域 -->
    <div class="box">
      <!-- 左侧内容 -->
      <el-card style="width:400px;margin-right:0px;padding:10px 20px">
        <div class="box_left">
          <ul style="padding:0;margin:0;">
            <li>
              <!-- 标题 -->
              <h3 style="margin:0;">{{ this.orderInfo.orderTitle }}</h3>
            </li>
            <!-- 订单号 -->
            <li>
              <span style="font-size: 14px;color: #000; line-height: 10px;">订单编号：</span>
              <span
                style="font-size: 14px;color: #a2a2a2;line-height: 20px;"
              >{{ this.orderInfo.orderNo }}</span>
            </li>
            <!-- 创建时间 -->
            <li>
              <span style="font-size: 14px;color: #000;">创建时间：</span>
              <span
                style="font-size: 14px;color: #a2a2a2;"
              >{{ this.orderInfo.createDate }}</span>
            </li>
            <!-- 订单状态 -->
            <li style="display:flex;">
              <span style="font-size: 14px;color: #000;">订单状态：</span>
              <span
                style="font-size: 14px;color: #67c23a;"
              >{{ this.orderInfo.orderStatus==100?'未开始': this.orderInfo.orderStatus==101?'进行中': this.orderInfo.orderStatus==102?'已完成': this.orderInfo.orderStatus==103?'已取消':''}}</span>
            </li>
            <!-- 上门地址 -->
            <li>
              <span style="font-size: 14px;color: #000; line-height: 10px;">上门地址：</span>
              <span
                style="font-size: 14px;color: #a2a2a2;line-height: 20px;"
              >{{this.res.farmAddress? this.res.farmAddress:this.res.farmName}}</span>
            </li>
            <!-- 联系人 -->
            <li>
              <span style="font-size: 14px;color: #000; line-height: 10px;">联系人：</span>
              <span
                style="font-size: 14px;color: #a2a2a2;line-height: 20px;"
              >{{ this.orderInfo.farmOwner}}</span>
            </li>
            <!-- 联系电话 -->
            <li>
              <span style="font-size: 14px;color: #000; line-height: 10px;">联系电话：</span>
              <span
                style="font-size: 14px;color: #a2a2a2;line-height: 20px;"
              >{{ this.orderInfo.farmPhone}}</span>
            </li>
         
           
          
            <!-- 现场照片 -->
            <li >
              <p style="font-size: 14px;color: #000; line-height: 10px;">查看采购合同：：</p>
              <div
                style="width:100px;height:100px;float:left;margin:2px;margin-top: 20px;"
                v-for="(item ,index) in  this.orderInfo.files"
                :key="index"
              >
                <el-image
                  style="width:100%;height:100%"
                  :src="img + item"
                  :preview-src-list="[img + item ]"
                ></el-image>
              </div>
            </li>
          </ul>
        </div>
      </el-card>
      <!-- 右侧内容 -->
      <el-card style="width:100%;padding:10px 20px">
        <div class="dd">订单详情</div>
        <div class="box_right" >
         
         
         
          <!-- 工长详情和结算详情区域 -->
          <div class="bigBox" >
           
             <div class="big">
              <div class="stitle">收购员详情</div>
              <div class="contain" >
              <div class="one">
                <div class="left">收购员名称</div>
                <div class="right">{{this.orderInfo.workerName? this.orderInfo.workerName :'暂未分配'}}</div>
              </div>
              <div class="one">
                <div class="left">收购员电话</div>
                <div class="right">{{this.orderInfo.workerPhone? this.orderInfo.workerPhone :'暂无'}}</div>
              </div>
              <div class="one">
                <div class="left">服务地区</div>
                <div class="right">{{this.res.farmAddress? this.res.farmAddress :this.res.farmName}}</div>
              </div>
              <div class="one">
                <div class="left">接单状态</div>
              
                  <div
                    style="color:#67c23a"
                    class="right"
                  >{{this.orderInfo.orderStatus==100?'未开始': this.orderInfo.orderStatus==101?'进行中': this.orderInfo.orderStatus==102?'已完成': this.orderInfo.orderStatus==103?'已取消':'暂未分配'}}</div>
               
              </div>
            </div>
           
             </div>
             
           
            
            
           
            <div  class="big">
            <div class="stitle">牧场详情</div>
            <div class="contain" >
            
            <div class="one">
              <div class="left">牧场名称</div>
              <div class="right">{{this.orderInfo.farmName}}</div>
            </div>
            <div class="one">
              <div class="left">详细地址</div>
              <div class="right">{{this.res.farmAddress? this.res.farmAddress:'暂无'}}</div>
            </div>
            <div class="one">
              <div class="left">计划详情</div>

                <div class="right">{{title}}</div>
             
            </div>
            <div class="one">
              <div class="left">备注</div>
             
                <div class="right">{{this.orderInfo.remark}}</div>
             
            </div>
             
         
          </div>
           </div>
            
          </div>

            <!-- --------------------------分割线----------------------------------- -->
           
          <!-- 底部区域 -->
          <div class="footer">
            <!-- 照片 -->
            <div class="footer_left">
              <h4 style="margin-bottom:30px">采购照片</h4>
            
              <ul  v-for="(item ,index) in shooping" :key="index">
                <p style="margin-bottom:20px;margin-top: 20px;" v-if="item.purchaseInfoVo.files.length!=0">{{item.planTitle}}</p>
                <template v-if="item.purchaseInfoVo.files!==[]">
                  <el-image
                    v-for=" (e ,index) in item.purchaseInfoVo.files"
                    :key="index"
                    style="width:100px; height:100px;margin-right:5px"
                    :src="img + e"
                    :preview-src-list="[img + e]"
                  ></el-image>
                </template>
              </ul> 
              <!-- <h4 style="margin-bottom:30px">订单照片</h4>
              <ul v-for="(item ,index) in orderLogList" :key="index">
                <p v-if="item.files && item.msg">{{item.msg}}</p>
                <template v-if="item.files">
                  <el-image
                    v-for=" (e ,index) in item.files"
                    :key="index"
                    style="width:100px; height:100px;margin-right:5px"
                    :src="img + e"
                    :preview-src-list="[img + e]"
                  ></el-image>
                </template>
              </ul> -->
            </div> 
            <div class="footer_left">
              <h4 style="margin-bottom:30px">肉联厂照片</h4>
              <ul v-for="(item ,index) in shooping" :key="index">
                <p  style="margin-bottom:20px;margin-top: 20px;" v-if="item.saleInfoVo.files.length!=0">{{item.planTitle}}</p>
                <template v-if="item.saleInfoVo.files">
                  <el-image
                    v-for=" (e ,index) in item.saleInfoVo.files"
                    :key="index"
                    style="width:100px; height:100px;margin-right:5px"
                    :src="img + e"
                    :preview-src-list="[img + e]"
                  ></el-image>
                </template>
              </ul>
                <!-- <ul v-for="(item ,index) in orderLogList" :key="index">
                <p v-if="item.files && item.msg">{{item.msg}}</p>
                <template v-if="item.files">
                  <el-image
                    v-for=" (e ,index) in item.files"
                    :key="index"
                    style="width:100px; height:100px;margin-right:5px"
                    :src="img + e"
                    :preview-src-list="[img + e]"
                  ></el-image>
                </template>
              </ul>  -->
            </div> 
            <!-- 操作日志 -->
            <div class="footer_right">
              <el-card>
                <h4>操作日志</h4>
              <!-- <el-table
                style="width: 100%;margin-top:20px;"
                height="500"
                stripe
                :data="orderLogList"
              >
                <el-table-column prop="msg" label="描述" header-align="center" width="250">
                  <template slot-scope="scope">
                    <el-tooltip effect="dark" :content="scope.row.msg" placement="top">
                      <div
                        style="width:100%;overflow:hidden;text-overflow:ellipsis;display:-webkit-box; -webkit-box-orient:vertical;-webkit-line-clamp:2;cursor:pointer;"
                      >{{scope.row.msg}}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column label="操作人" prop="operateUserName" align="center"></el-table-column>
                <el-table-column label="创建时间" prop="createDate" align="center" width="160">
                  <template slot-scope="scope">{{scope.row.createDate }}</template>
                </el-table-column>
              </el-table> -->
              <div style="margin-top:20px" v-for="(item ,index) in orderLogList" :key="index">
                <el-row type="flex" class="row-bg">
                  <el-col :span="24"><div >{{item.msg}}</div></el-col>
                </el-row>
                <!-- <el-row type="flex" class="row-bg"  v-if="item.files">
                  <el-col :span="24"><div  style="margin-top:10px">
                    <template v-if="item.files">
                  <el-image
                    v-for=" (e ,index) in item.files"
                    :key="index"
                    style="width:100px; height:100px;margin-right:5px"
                    :src="img + e"
                    :preview-src-list="[img + e]"
                  ></el-image>
                </template>
              </div>
            </el-col>
                </el-row> -->
                <el-row type="flex" class="row-bg">
                  <el-col :span="24"><div  style="margin-top:10px">{{item.createDate}}</div></el-col>
                </el-row>
              </div>

              </el-card>

            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- --------------------------------------------- -->
   

    
  </div>
</template>

<script>
import {
  getOrderDetail,

  getOrderLogList, //获取订单操作流程
  getOrderAppointment,
  getOrderList

} from "../../request/http";
export default {
  data() {
    return {
      dialogVisible: false,
      loading:true,//加载动画
      orderId: "", //订单id
      orderNo: "", //订单号,
      orderInfo: {}, //订单详情信息
     shooping:[],
    res:[],
    
     
    
      //按条件获取工长信息
    title:[],
      count: 0,
      orderLogList: [],
      
     
     
    };
  },
  created() {
    this.orderId = this.$route.query.orderId; //订单id
    this.orderNo = this.$route.query.orderNo; //订单号
    this.getOrderDetail(); // 获取订单详情
    this.getOrderList()
 this.getOrderAppointment()

    this.getOrderLog(); //获取订单操作流程
    // this.getMaster();
  },
  computed: {},

  methods: {
    goback() {
      this.$router.go(-1)
        },
      
    // 获取订单详情
    async getOrderDetail() {
      const { data } = await getOrderDetail( this.orderId );
    
      let list =data.data
      if (data.code !== 0) {
        return this.$message.error(data.msg);
      }
      this.orderInfo = list;
     console.log(this.orderInfo);
      this.orderInfo.purchaseInfoList.forEach(item => {
        let b = 
        item.goodsName + item.purchaseNum+" "
          
          
         this.title.push(b);

       
      });
      
   this.title=String(this.title)

 
    },
  
   
   
   
   

    //获取订单操作流程
    async getOrderLog() {
      const {data} = await getOrderLogList( this.orderId);
      
      data.data.forEach(item => {
                if(item.files){
              
                  item.files =item.files.split(",")
                }
            });
      this.orderLogList = data.data;
      console.log(this.orderLogList, "获取订单操作流程");
      this.loading = false
    },
 
   
    async getOrderAppointment() {
      const data = await getOrderAppointment( this.orderId);
         console.log(data.data.data);
      
      this.shooping = data.data.data;
     
    },
   
    async  getOrderList() {
      const {data} = await getOrderList({orderNo:this.orderNo});
      console.log(data.data, "获取订单");
       this.res=data.data.records[0]
       console.log(this.res);
      
    },
   
  
   
  }
};
</script>

<style lang="scss" scoped>
.order_container {
  .box {
    display: flex;
    .box_left {
      ul li {
        list-style: none;
        margin-top: 20px;
      }
    }
    .box_right {
      .el-button {
        width: 112px;
        height: 40px;
        color: #d0021bff;
        background: rgba(208, 2, 27, 0.05);
        border-radius: 4px;
        border: 1px solid #d0021b;
        margin-left: 20px;
      }
      .select_box {
        margin-left: 35px;
      }
      .el-select {
        width: 200px;
        margin-right: 10px;
      }
      .btn {
        width: 80px;
        height: 32px;
        color: #ffffff;
        background: #007afc;
        border-radius: 4px;
        border: 1px solid #007afc;
      }
      .ipt {
        width: 200px;
        height: 40px;
        margin-right: 10px;
      }
      .btn_one {
        width: 60px;
        height: 30px;
        color: #ffffff;
        background: #007afc;
        border-radius: 4px;
        border: 1px solid #007afc;
      }
      .bigBox {
        display: flex;
        justify-content: space-between;
        &::after {
     content: "";
    width: 1px;
    }
       
        .contain {
          width: 100%;
          padding: 0 10px 0 10px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          border: 1px solid #e9e9e9;
          // margin: auto;
          .one {
            display: flex;
            justify-content: space-between;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #dededeff;
            .left {
              font-size: 14px;
              font-family: PingFang-SC-Medium, PingFang-SC;
              color: rgba(0, 0, 0, 0.85);
            }
            .right {
              width: 250px;
              font-size: 14px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              color: rgba(0, 0, 0, 0.85);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: right;
              cursor: pointer;
            }
          }
        }
      }
    }
    .el-card__body {
      padding: 0;
      margin: 0;
    }
    .footer {
      display: flex;
      margin-top: 80px;

      .footer_left {
        width: 50%;
        h5 {
          margin: 0;
        }
      }
      .footer_right {
        width: 70%;
        h5 {
          margin: 0;
        }
      }
    }
  }
  li {
    list-style: none;
  }
  .btn {
    width: 80px;
    height: 32px;
    color: #ffffff;
    background: #007afc;
    border-radius: 4px;
    border: 1px solid #007afc;
  }
  .ipt {
    width: 200px;
    height: 40px;
    margin-right: 10px;
  }
  .el-select {
    width: 200px;
    margin-right: 10px;
  }
}
.dd{
  margin-bottom: 30px;
  font-size: 16px 600;
}
.big{
 

       
 
}
.stitle{
  margin-bottom: 20px;
  font-size: 14px 600
}
.ph{
    margin-bottom: 20px;
}
</style>



 
          